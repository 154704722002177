<template>
  <section id="iq-upcoming-movie">











    <b-container fluid>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div class="iq-main-header d-flex align-items-center justify-content-between">
            <h4 class="main-title">
              <div v-if="$i18n.locale == 'en'">My Contents </div>
              <div v-if="$i18n.locale == 'ku'"> ناوەڕۆکەکانم </div>
              <div v-if="$i18n.locale == 'ar'"> محتوياتي </div>
            </h4>
          </div>


















          <div class="tabs">
            <button :class="{ active: activeTab === 'images' }" @click="activeTab = 'images'"
              v-if="hasItems('imageContents')">Images</button>
            <button :class="{ active: activeTab === 'videos' }" @click="activeTab = 'videos'"
              v-if="hasItems('videoContents')">Videos</button>
          </div>

          <br>

          <div class="favorites-contens">

            <!-- video contents -->
            <div class="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction" v-if="activeTab === 'videos'"
              v-show="activeTab === 'videos'">
              <li class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3" v-for="(item, index) in videoContents"
                :key="index">










                <div class="block-images position-relative">
                  <div class="img-box">

                    <img :src="item.content_thumbnail" class="img-fluid" alt="">

                    <div class="block-description">

                      <h6 class="iq-title"><a @click="gotoPage('/movie-detail/' + item.id)">
                        </a></h6>

                      <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                        <a @click="gotoPage('/movie-detail/' + item.id)" role="button" class="btn btn-hover"><i
                            class="fa fa-play mr-1" aria-hidden="true"> Play Now </i>
                        </a>
                      </div>

                      <div class="hover-buttons" v-if="$i18n.locale == 'ku'">
                        <a @click="gotoPage('/movie-detail/' + item.id)" role="button" class="btn btn-hover"><i
                            class="fa fa-play mr-1" aria-hidden="true"> ڤیدیۆکە لێبدە </i>
                        </a>
                      </div>


                      <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                        <a @click="gotoPage('/movie-detail/' + item.id)" role="button" class="btn btn-hover"><i
                            class="fa fa-play mr-1" aria-hidden="true"> شغل الفيديو </i>
                        </a>
                      </div>

                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- video -->


            <!-- image contents -->
            <div class="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction" v-if="activeTab === 'images'"
              v-show="activeTab === 'images'">
              <li class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3" v-for="item in imageContents" :key="item.id"
                v-show="item.content_url[$i18n.locale] != null">

                <div class="block-images position-relative">
                  <div class="img-box">

                    <img :src="item.content_url[$i18n.locale]" class="img-fluid" alt="">

                    <div class="block-description">

                      <h6 class="iq-title"><a @click="gotoPage('/movie-detail/' + item.id)">
                        </a></h6>

                      <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                        <a @click="gotoPage('/movie-detail/' + item.id)" role="button" class="btn btn-hover"><i
                            class="fa fa-play mr-1" aria-hidden="true"> View </i>
                        </a>
                      </div>

                      <div class="hover-buttons" v-if="$i18n.locale == 'ku'">
                        <a @click="gotoPage('/movie-detail/' + item.id)" role="button" class="btn btn-hover"><i
                            class="fa fa-play mr-1" aria-hidden="true"> بینین </i>
                        </a>
                      </div>


                      <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                        <a @click="gotoPage('/movie-detail/' + item.id)" role="button" class="btn btn-hover"><i
                            class="fa fa-play mr-1" aria-hidden="true"> عرض </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- image -->
          </div>
          <!-- </Slick> -->
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import ApiService from '../../../services/api'
import AlertServie from '../../../services/errors'



export default {
  name: 'PopularShow',
  props: ['videoContents', 'imageContents'],
  components: {
  },
  mounted() {
  },
  data() {
    return {

      activeTab: 'images',

      showImagePopup: false,
      imageContentPath: '',
      favOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        prevArrow: '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow: '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }

    }
  },
  methods: {

    hasItems(tab) {
      // Check if the given tab has items
      return this[tab].length > 0
    },

    gotoPage(link) {
      var points = this.$store.state.points
      if (points > 0) {
        this.$router.push(link)
      } else {
          if (this.$i18n.locale === 'en') {
            AlertServie.info("You have used all your daily points. You will receive 3 more points tomorrow.")
          } else if (this.$i18n.locale === 'ku') {
            AlertServie.info(".هەموو خاڵەکانی ڕۆژانەت بەکارهێناوە. بەیانی 3 خاڵی تر وەردەگریت")
          } else {
            AlertServie.info(".لقد استخدمت كل نقاطك اليومية. ستحصل على 3 نقاط إضافية غدًا")
          }
      }
    },


    next() {
      this.$refs.dSlick.next()
    },
    prev() {
      this.$refs.dSlick.prev()
    },
    closeContent() {
      this.imageContentPath = ""
      this.showImagePopup = false
      //  this.imagePopup = false
    },
    openContent(id) {
      this.showImagePopup = true
      var self = this
      ApiService.openContent(id).then((response) => {
        this.$store.commit('setPoints', response.data.user_points)
        self.imageContentPath = response.data.data.content_url.ku
      })
    }
  }
}
</script>








<style scoped lang="scss">
.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;

  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.main-title {
  font-size: 22px;
  align-items: left;
  padding-right: 1240px;
  padding-top: 100px;
}

.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;

  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}

.tabs {
  display: flex;
}

button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

button.active {
  background-color: #a02278;
  color: #f0f0f0
}

.tab-content {
  margin-top: 20px;
}
</style>
